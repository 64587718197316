.animatedGradient {
    /* height: 100vh; */
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    overflow-x:hidden;
    /* height:auto ; */
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
    animation: animateBg 30s linear infinite;
    background-image: linear-gradient(120deg, 
    hsl(240, 50%, 10%),
    hsl(270, 50%, 10%),
    hsl(315, 50%, 10%),
    hsl(345, 50%, 10%),
    hsl(0, 50%, 10%) ,
    hsl(90, 50%, 10%),
    hsl(150, 50%, 10%),
    hsl(180, 50%, 10%),
    hsl(210, 50%, 10%),
    hsl(240, 50%, 10%)
    /* hsl(255, 50%, 10%), */
    /* hsl(285, 50%, 10%), */
    /* hsl(300, 50%, 10%), */
    /* hsl(330, 50%, 10%), */
    /* hsl(15, 50%, 10%), */
    /* hsl(30, 50%, 10%), */
    /* hsl(35, 50%, 10%), */
    /* hsl(40, 50%, 10%), */
    /* hsl(45, 50%, 10%), */
    /* hsl(50, 50%, 10%), */
    /* hsl(55, 50%, 10%), */
    /* hsl(60, 50%, 10%),  */
    /* hsl(75, 50%, 10%), */
    /* hsl(105, 50%, 10%), */
    /* hsl(120, 50%, 10%), */
    /* hsl(135, 50%, 10%), */
    /* hsl(165, 50%, 10%), */
    /* hsl(170, 50%, 10%), */
    /* hsl(175, 50%, 10%), */
    /* hsl(185, 50%, 10%), */
    /* hsl(195, 50%, 10%), */
    /* hsl(200, 50%, 10%), */
    /* hsl(205, 50%, 10%), */
    /* hsl(215, 50%, 10%), */
    /* hsl(220, 50%, 10%), */
    /* hsl(225, 50%, 10%) */
    );
    background-size: 2000% 1000%;
    /* background-size: 100% 100%; */
}

::-webkit-scrollbar{
    display: none;
}

@keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
    /* 25% { background-position: 50% 50%; } */
    /* 50% { background-position: 0% 0%; } */
    /* 75% { background-position: 100% 100%;} */
}